// src/components/HighScoreTable.js
import React, { useEffect, useState } from 'react';
import { Container, Button } from '../styles';
import { supabase } from '../supabase';
import { useNavigate } from 'react-router-dom';

const HighScoreTable = ({ userName, score, resetGame }) => {
  const [highScores, setHighScores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const saveAndFetchScores = async () => {
      try {
        // Save new score to Supabase
        const { error: insertError } = await supabase
          .from('high_scores')
          .insert([{ name: userName, score }]);

        if (insertError) {
          console.error('Error saving high score:', insertError);
          return;
        }

        // Fetch top 10 high scores
        const { data, error: fetchError } = await supabase
          .from('high_scores')
          .select('*')
          .order('score', { ascending: false })
          .limit(10);

        if (fetchError) {
          console.error('Error fetching high scores:', fetchError);
        } else {
          setHighScores(data);
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      }
    };

    saveAndFetchScores();
  }, [userName, score]);

  const handlePlayAgain = () => {
    resetGame();
    navigate('/');
  };

  return (
    <Container>
      <h1>High Scores</h1>
      {highScores.length > 0 ? (
        <ol>
          {highScores.map((entry) => (
            <li key={entry.id}>
              {entry.name}: {entry.score}
            </li>
          ))}
        </ol>
      ) : (
        <p>No high scores yet.</p>
      )}
      <Button onClick={handlePlayAgain}>Play Again</Button>
    </Container>
  );
};

export default HighScoreTable;
