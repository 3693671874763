import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Registration from './components/Registration';
import TriviaGame from './components/TriviaGame';
import HighScoreTable from './components/HighScoreTable';

const App = () => {
  const [userName, setUserName] = useState('');
  const [gameScore, setGameScore] = useState(null);

  const handleRegister = (name) => {
    setUserName(name);
  };

  const handleGameEnd = (score) => {
    setGameScore(score);
  };

  // Add resetGame function here
  const resetGame = () => {
    setUserName('');
    setGameScore(null);
  };
  
  

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Registration onRegister={handleRegister} />}
        />
        <Route
          path="/game"
          element={
            <TriviaGame userName={userName} onGameEnd={handleGameEnd} />
          }
        />
        <Route
          path="/high-scores"
          element={
            <HighScoreTable
              userName={userName}
              score={gameScore}
              resetGame={resetGame}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;