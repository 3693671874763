// src/styles.js
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #000;
  color: #00f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em; /* Increase font size */
`;

export const Button = styled.button`
  background-color: #00f;
  color: #fff;
  border: none;
  padding: 15px 30px; /* Increase padding */
  margin: 10px;
  font-size: 1.2em; /* Increase font size */
  cursor: pointer;
  border-radius: 5px;
  width: 300px; /* Increase width */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005ce6;
  }
`;

export const Input = styled.input`
  padding: 15px;
  margin: 10px;
  font-size: 1.2em; /* Increase font size */
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px; /* Increase width */
`;

export const Title = styled.h1`
  font-size: 3em; /* Increase title size */
  margin-bottom: 20px;
`;

export const Subtitle = styled.h2`
  font-size: 2em; /* Increase subtitle size */
  margin-bottom: 20px;
`;
export const AnswerButton = styled(Button)`
  background-color: ${(props) =>
    props.isSelected
      ? props.isCorrect
        ? 'green'
        : 'red'
      : '#00f'};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: background-color 0.3s ease;
`;

