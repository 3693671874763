// src/components/TriviaGame.js
import React, { useState, useEffect } from 'react';
import { Container, Button } from '../styles';
import { useNavigate } from 'react-router-dom';

const TriviaGame = ({ userName, onGameEnd }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  // Fetch questions from Open Trivia Database
  useEffect(() => {
    fetch('https://opentdb.com/api.php?amount=5&type=multiple')
      .then((res) => res.json())
      .then((data) => {
        const formattedQuestions = data.results.map((q) => {
          const options = [...q.incorrect_answers, q.correct_answer];
          return {
            question: q.question,
            options: options.sort(() => Math.random() - 0.5),
            answer: q.correct_answer,
          };
        });
        setQuestions(formattedQuestions);
      });
  }, []);

  // Handle case when questions are not yet loaded
  if (questions.length === 0) {
    return <Container>Loading Questions...</Container>;
  }

  // Define currentQuestion after ensuring questions are loaded
  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (option) => {
    const isCorrect = option === currentQuestion.answer;

    // Set selected option and correctness
    setSelectedOption(option);
    setIsAnswerCorrect(isCorrect);
  
    // Delay to show color feedback before moving to the next question
    setTimeout(() => {
      if (isCorrect) {
        setScore(score + 1);
      } else {
        setIncorrectAnswers(incorrectAnswers + 1);
      }
  
      // Check if the user has reached 3 incorrect answers
      if (incorrectAnswers + (!isCorrect ? 1 : 0) >= 3) {
        onGameEnd(score);
        navigate('/high-scores');
        return;
      }
  
      const nextIndex = currentQuestionIndex + 1;
  
      if (nextIndex < questions.length) {
        setCurrentQuestionIndex(nextIndex);
      } else {
        onGameEnd(score);
        navigate('/high-scores');
      }
  
      // Reset selected option for the next question
      setSelectedOption(null);
      setIsAnswerCorrect(null);
    }, 1000); // Delay of 1 second
  };

  return (
    <Container>
      <h2 dangerouslySetInnerHTML={{ __html: currentQuestion.question }} />
      <p>Incorrect Answers: {incorrectAnswers} / 3</p>
      {currentQuestion.options.map((option) => {
  let buttonColor = '#00f'; // Default blue

  if (selectedOption !== null) {
    if (option === currentQuestion.answer) {
      buttonColor = 'green'; // Correct answer
    } else if (option === selectedOption && !isAnswerCorrect) {
      buttonColor = 'red'; // Incorrect selected answer
    } else {
      buttonColor = '#00f'; // Other options remain default
    }
  }

  return (
    <Button
      key={option}
      onClick={() => handleAnswer(option)}
      dangerouslySetInnerHTML={{ __html: option }}
      style={{ backgroundColor: buttonColor }}
      disabled={selectedOption !== null}
    />
  );
})}
    </Container>
  );
};

export default TriviaGame;
